import React from 'react';
import tikawork from './assets/tikawork.png';
import './App.css';
import './index.css';

function App() {
  return (
    <div className="container mx-auto flex flex-col min-h-screen">
      {/* Content */}
      <div className="flex-grow">
        {/* logo */}
        <div className='flex justify-center items-center mt-10 h-3/6'>
          <img src={tikawork} alt="logo" className="w-3/6" />
        </div>
        <div className='flex flex-col justify-center items-start mt-10 md:flex-row'>
          {/* column1 */}
          <div className='box min-h-50 w-full md:w-3/6 p-5 border-b md:border-r md:border-b-0 border-gray-600'>
            <p className='text-center md:text-center'>Open source, modular data annotation tool</p>
            <div className='text-center justify-center flex items-center mt-3'>
              {/* github link */}
              <span className="fa fa-github text-4xl mr-3 p-3"></span>
              <a href="https://github.com/annosmart/tika-work.git" target="_blank" rel="noreferrer" className='text-indigo-600'>
                https://github.com/annosmart/tika-work.git
              </a>
              {/* demoapp-link */}
              <a href="https://demo.tika-work.com" className="rounded-full bg-indigo-600 px-2.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-5"
                target="_blank" rel="noreferrer">
                Demo
              </a>
            </div>

            <p className='text-center justify-center flex items-center mt-4'>
              <span className='font-bold'>Installers</span>
              {/* windows */}
              <a href='./assets/executables/windows/tika-work-1.0.0.exe' className="fa fa-windows text-4xl ml-10 mr-10"></a>
              {/* mac */}
              <a href='./assets/executables/mac/tika-work-1.0.0.dmg' className="fa fa-apple text-4xl mr-10"></a>
              {/* docker */}
              <a href='https://hub.docker.com/r/annosmart/tika-work' className="fa fa-docker text-4xl mr-10" target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" height="36" width="50" viewBox="0 0 640 512">
                  <path d="M349.9 236.3h-66.1v-59.4h66.1v59.4zm0-204.3h-66.1v60.7h66.1V32zm78.2 144.8H362v59.4h66.1v-59.4zm-156.3-72.1h-66.1v60.1h66.1v-60.1zm78.1 0h-66.1v60.1h66.1v-60.1zm276.8 100c-14.4-9.7-47.6-13.2-73.1-8.4-3.3-24-16.7-44.9-41.1-63.7l-14-9.3-9.3 14c-18.4 27.8-23.4 73.6-3.7 103.8-8.7 4.7-25.8 11.1-48.4 10.7H2.4c-8.7 50.8 5.8 116.8 44 162.1 37.1 43.9 92.7 66.2 165.4 66.2 157.4 0 273.9-72.5 328.4-204.2 21.4 .4 67.6 .1 91.3-45.2 1.5-2.5 6.6-13.2 8.5-17.1l-13.3-8.9zm-511.1-27.9h-66v59.4h66.1v-59.4zm78.1 0h-66.1v59.4h66.1v-59.4zm78.1 0h-66.1v59.4h66.1v-59.4zm-78.1-72.1h-66.1v60.1h66.1v-60.1z" />
                </svg>
              </a>
            </p>
          </div>
          {/* column2 */}
          <div className='box min-h-50 justify-center items-center w-full md:w-3/6 p-5 mt-5 md:mt-0'>
            <p className='text-center'>Fully customizable, scalable annotation platform for high output teams</p>
            <h1 className='text-6xl mt-5 text-center'>COMING SOON</h1>
          </div>
        </div>
      </div>
      {/* Footer */}
      {/* ©2024 annosmart technologies inc.. All Rights Reserved. */}
      <footer className='text-center pb-10'>
        <p>©{new Date().getFullYear()} Annosmart Technologies Inc. Canada. All Rights Reserved.</p>
      </footer>
    </div>
  );
}

export default App;
